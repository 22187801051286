import { FC, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CommonFormikComponent from "../../../components/common/CommonFormikComponent";
import ThemeCustomSelect from "../../../components/common/CustomSelect";
import ThemeInputText from "../../../components/common/InputText";
import DataGridCommon from "../../../components/common/dataGrid/DataGridCommon";
import FileUploaderDragCustom from "../../../components/common/FileUploaderDrag";
import ThemeInputTextarea from "../../../components/common/InputTextarea";
import Loader from "../../../components/common/Loader";
import RenderAction from "../../../components/common/dataGrid/ActionCell";
import { UploadDocumentModalSchema } from "../../../validations/documents";
import { documentActions, edituploadCaseDocumentsByCaseAction, getAllApplicantList, getDocDetialsByIDAction, getKnowledgeBaseDocTypesListAction, uploadCaseDocumentsByCaseAction } from "../../../redux/documentModule/documentSlice";
import { DOCUMENTS_MAX_ALLOWED_FILES_SIZE_250MB_ALLOWED, DOCUMENTS_MAX_FILES_SIZE_ALLOWED, MAX_FIFTY_FILES_ALLOWED, MAX_FIFTY_FILES_ALLOWED_COUNT, POP_UP_CLOSE_2000 } from "../../../constants/global";
import ThemeCustomMultiSelect from "../../../components/common/CustomMultiSelect";
import FileUploaderDragAllowedCustomSizeAndErrorMsgWithType from "../../../components/common/FileUploaderDragAllowedCustomSizeAndErrorMsgWithType";

interface IUploadDocumentModal {
  show?: boolean;
  handleClose?: any;
  selectedCaseFolder?: any
  docRowData?: any;
  caseOptions?: any;
  caseNumber?: any;
  caseID?: any;
  tentativeSharePointAvailable?: any;
}

/**
 * Edit Document Modal Component
 *
 * @param {{ show: any; handleClose: any; }} {
  show,
  handleClose,
}
 * @returns {*}
 */

const UploadDocumentModal: FC<IUploadDocumentModal> = ({
  show,
  handleClose,
  selectedCaseFolder,
  docRowData,
  caseOptions,
  caseNumber,
  caseID,
  tentativeSharePointAvailable,
}) => {

  // Initial Values
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector((state: any) => state.documentSlice.loading);
  const [filesArr, setFilesArr] = useState<any>([]);
  const [typeData, setTypeData] = useState([]);
  const [tableData, setTableData] = useState<any>([]);
  const [uploadedTableData, setUploadedTableData] = useState<any>([]);
  const [actionType, setActionType] = useState("Upload");
  const [applicantList, setApplicantList] = useState<any>([]);
  const [documentData, setDocumentData] = useState<any>({});


  useEffect(() => {
    if (!show) return;
    fetchDocTypeList();
    GetApplicantList();
    setTableData([]);
    setUploadedTableData([]);
    setFilesArr([]);
    if (!show) return;
    // dispatch(documentActions.startLoader(true));
    // const masterPayload = {
    //   doc_id: docRowData?.id,
    // };
    // dispatch(getDocDetialsByIDAction(masterPayload)).then((data: any) => {
    //   let _data = data.payload.data;
    //   setDocumentData(_data);
    //   setUploadedTableData(_data.uploaded_documents_history);
    //   console.log(_data);
    //   dispatch(documentActions.stopLoader(true));
    // }).catch((err: any) => {
    //   console.log(err);
    // });
  }, [show])

  const fetchDocTypeList = () => {
    dispatch(getKnowledgeBaseDocTypesListAction()).then((res) => {
      let respData = res.payload ? res.payload.data : []
      if (respData.length > 0) {
        const typeDDListData = respData?.map((item: any) => ({
          label: item.type_name,
          value: item,
        }));
        setTypeData(typeDDListData);
      }
    }).catch((err) => {
      console.log("##### catch #####", err)
    })
  }

  const GetApplicantList = () => {
    const masterPayload = {
      caseTypesID: caseOptions
    };
    dispatch(getAllApplicantList(masterPayload)).then((data: any): void => {
      let applicantData: any = []
      data.payload.data.applicant_info.forEach((element: any) => {
        applicantData.push({
          label: element.client_name, value: element
        })
      });
      setApplicantList(applicantData);
      dispatch(documentActions.stopLoader(true));
    }).catch((err: any) => {
      console.log(err);
    });
  }

  // Handle Submit
  const handleSubmit = async (values: Record<string, any>, actions: any) => {
    actions.setSubmitting(false);
    // Max 50 files allowed
    if(Number(tableData?.length) > Number(MAX_FIFTY_FILES_ALLOWED_COUNT) ) {
      toast.error(MAX_FIFTY_FILES_ALLOWED, { autoClose: POP_UP_CLOSE_2000 });
      return;
    }

    // Check if the total size would exceed 250 MB after adding the new files
    const totalSize = filesArr?.reduce((acc: number, file: any) => acc + file.size,0);
    if ( totalSize > Number(DOCUMENTS_MAX_FILES_SIZE_ALLOWED) * 1024 * 1024) {
      toast.error(`Could not upload the file(s) because adding the selected file(s) would exceed the ${DOCUMENTS_MAX_FILES_SIZE_ALLOWED} MB limit`);
      return;
    }
    handleFileUploadOnSharePoint(values);
  };

  const handlefileChange = (filesArrArg: any) => {
    // Max 50 files allowed
    if ( (Number(filesArrArg?.length) + Number(tableData?.length)) > Number(MAX_FIFTY_FILES_ALLOWED_COUNT) ) {
      toast.error(MAX_FIFTY_FILES_ALLOWED, { autoClose: POP_UP_CLOSE_2000 });
      return;
    }
    // Check if the total size would exceed 250 MB after adding the new files
    const totalSize = filesArr?.reduce((acc: number, file: any) => acc + file.size,0);
    // Convert FileList to array
    const filesArraySize = Array.from(filesArrArg)?.reduce((acc: number, file: any) => acc + file.size,0);
    if((Number(filesArraySize) + Number(totalSize)) > Number(DOCUMENTS_MAX_FILES_SIZE_ALLOWED) * 1024 * 1024) {
      toast.error(`Could not upload the file(s) because adding the selected file(s) would exceed the ${DOCUMENTS_MAX_FILES_SIZE_ALLOWED} MB limit`);
      return;
    }

    const lengthOfData = tableData.length;
    let tempData = JSON.parse(JSON.stringify(tableData));
    let filelistObj = filesArr;
    let tempFileData: any = Array.from(filesArrArg);
    for (let i = 0; i < filesArrArg.length; i++) {
      let fileObj = { s_no: 0, fileName: "" };
      fileObj.s_no = lengthOfData + i + 1;
      fileObj.fileName = filesArrArg[i].name;
      tempData.push(fileObj);
      filelistObj.push(tempFileData[i]);
    }
    setTableData(tempData);
    setFilesArr(filelistObj);
  }

  const handleFileUploadOnSharePoint = async (valuesFormData: Record<string, any>) => {
    dispatch(documentActions.startLoader(true));
    const formData = new FormData();
    formData.append('case_id', caseID);
    formData.append('document_name', valuesFormData.document_name);
    formData.append('document_type', valuesFormData.document_type?.id);
    formData.append('ch_case_id', caseNumber ?? "");
    formData.append('description', valuesFormData?.description ?? "");
    formData.append('checklist_for_applicant', JSON.stringify(valuesFormData.applicants));
    formData.append('parent_upload_to_folder', "B. Client uploaded files");
    formData.append('upload_to_folder', "B. Client uploaded files");
    if (filesArr.length > 0) {
      filesArr.forEach((element: any) => {
        formData.append("files", element);
      });
      dispatch(uploadCaseDocumentsByCaseAction({formData, tennantSharepointDriveId: tentativeSharePointAvailable })).then((res: any) => {
        if (res.payload?.statusCode || res.payload?.data) {
          dispatch(documentActions.stopLoader(true));
          handleClose();
        } else {
          dispatch(documentActions.stopLoader(true));
        }
      }).catch((err: any) => {
        dispatch(documentActions.stopLoader(true));
        console.log("########## uploadLargeFile ##########", err);
      })
    } else {
      toast.error("Atleast One file should be selected", { autoClose: POP_UP_CLOSE_2000 });
      dispatch(documentActions.stopLoader(true));
      return
    }
  }

  //Handle Delete
  const handleDeleteClick = (rowData: any, index: any) => {
    filesArr.splice(index?.rowIndex, 1);
    tableData.splice(index?.rowIndex, 1);
    for (let i = index; i < tableData.length; i++) {
      tableData[i].s_no = i + 1;
    }
    setTableData([...tableData]);
    setFilesArr([...filesArr]);
  };

  const sNoCellTemplate = (docRowData: any, index: any) => {
    return <span className="min-w-20 d-inline-block text-center">{index?.rowIndex + 1}</span>
  }

  //Action Template
  const actionCellTemplate = (rowData: any, index: any) => {
    return (
      <RenderAction
        controlId="knowledge-base-doc-checklist-items"
        redirectID={rowData.id}
        isViewEnable={false}
        isDeleteEnable={true}
        handleDeleteClick={() => handleDeleteClick(rowData, index)}
        rowData={rowData}
      />
    );
  };

  // Table Columns
  const columns = [
    {
      field: "s_no",
      header: "No.",
      sortable: false,
      body: sNoCellTemplate,
      width: '120px',
    },
    {
      field: "fileName",
      header: "File Name",
      sortable: false,
    },
    {
      field: "action",
      header: "Action",
      headerClassName: "theme-action-cell",
      width: "90px",
      body: actionCellTemplate,
    },
  ];

  return (

    <>
      <Loader isLoading={isLoading} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
        scrollable={true}
        size="lg"
        className="theme-modal"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="fw-semibold">
            Upload New Document
          </Modal.Title>
        </Modal.Header>
        <CommonFormikComponent
          validationSchema={UploadDocumentModalSchema}
          initialValues={{
            document_name: documentData?.document_name ?? "",
            document_type: documentData?.document_type ?? "",
            upload_to_folder: documentData?.checklist_item_detail?.upload_to_folder + ";" + documentData?.checklist_item_detail?.parent_upload_to_folder ?? "",
            description: documentData?.description ?? "",
            applicants: []
          }}
          onSubmit={handleSubmit}
        >
          {(formikProps: any) => (
            <>
              <Modal.Body className="pt-3">
                <Row className="g-3">
                  <Col sm={6}>
                    <ThemeInputText
                      controlId="document_name"
                      label='Document Name*'
                      handleBlur={formikProps.handleBlur}
                      handleChange={formikProps.handleChange}
                      value={formikProps.values.document_name}
                      touched={formikProps.touched.document_name}
                      errorsField={formikProps.errors.document_name}
                    />
                  </Col>
                  <Col sm={6}>
                    <ThemeCustomSelect
                      controlId="document_type"
                      label="Document Type*"
                      placeHolder="Select"
                      data={typeData}
                      handleBlur={formikProps.handleBlur}
                      handleChange={formikProps.handleChange}
                      value={formikProps.values.document_type}
                      touched={formikProps.touched.document_type}
                      errorsField={formikProps.errors.document_type}
                    />
                  </Col>
                  <Col sm={6}>
                    <ThemeCustomMultiSelect
                      label="Applicants*"
                      controlId="applicants"
                      id="applicants"
                      placeHolder="Applicants"
                      data={applicantList}
                      handleBlur={formikProps.handleBlur}
                      handleChange={formikProps.handleChange}
                      value={formikProps.values.applicants}
                      touched={formikProps.touched.applicants}
                      errorsField={formikProps.errors.applicants}
                    />
                  </Col>
                  <Col sm={12}>
                    <ThemeInputTextarea
                      rows={3}
                      controlId="description"
                      label='Description'
                      handleBlur={formikProps.handleBlur}
                      handleChange={formikProps.handleChange}
                      value={formikProps.values.description}
                      touched={formikProps.touched.description}
                      errorsField={formikProps.errors.description}
                    />
                  </Col>
                  {actionType === 'Upload' && !formikProps.values.move_to_folder ?
                    <Col sm={12}>
                      <FileUploaderDragAllowedCustomSizeAndErrorMsgWithType
                        controlId="sample_document"
                        label={formikProps.values.document_type?.allowed_file_types ? `Document Allowed file types - (${formikProps.values.document_type?.allowed_file_types?.join() ?? ""})` : ""}
                        fileTypes={formikProps.values.document_type?.allowed_file_types}
                        handlefileChange={handlefileChange}
                        multiple={true}
                        maxSize={DOCUMENTS_MAX_FILES_SIZE_ALLOWED}
                        sizeErrorMessage={DOCUMENTS_MAX_ALLOWED_FILES_SIZE_250MB_ALLOWED}
                      />
                    </Col> : ""}
                  {
                    actionType === 'Upload' &&
                    <>
                      <div className="px-1">
                        <Stack
                          direction="horizontal"
                          gap={1}
                          className="flex-wrap px-3 pt-3 pb-2 align-items-start"
                        >
                          <h5 className="mb-0 position-relative fw-normal">
                            Selected Files
                          </h5>
                        </Stack>
                      </div>
                      <div className="mb-3 pb-1 mt-2">
                        <DataGridCommon
                          columns={columns}
                          data={tableData}
                          HideTablePaginator={true}
                        />
                      </div>
                    </>
                  }
                </Row>
              </Modal.Body>
              <Modal.Footer className="py-2">
                <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
                  <Button
                    variant="outline-primary"
                    onClick={handleClose}
                    className="min-w-80"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    className="min-w-80"
                  >
                    Submit
                  </Button>
                </Stack>
              </Modal.Footer>
            </>
          )}
        </CommonFormikComponent>
      </Modal>
    </>
  );
};

export default UploadDocumentModal;
